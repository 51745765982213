import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
// import $ from 'jquery/dist/jquery.slim'

import laws15 from '../assets/images/work/laws-15.png'
import laws14 from '../assets/images/work/laws-14.png'
import laws12 from '../assets/images/work/laws-12.png'
import laws10 from '../assets/images/work/laws-10.png'
import laws09 from '../assets/images/work/laws-09.png'
import laws08 from '../assets/images/work/laws-08.png'
import laws07 from '../assets/images/work/laws-07.png'
import laws06 from '../assets/images/work/laws-06.png'
import laws04 from '../assets/images/work/laws-04.png'
import laws03 from '../assets/images/work/laws-03.png'
import laws02 from '../assets/images/work/laws-02.png'
import laws01 from '../assets/images/work/laws-01.png'

import one_benchmarking from '../assets/images/process/one-benchmarking.svg'
import one_trends from '../assets/images/process/one-trends.svg'
import one_market from '../assets/images/process/one-market.svg'
import one_workshops from '../assets/images/process/one-workshops.svg'
import one_surveys from '../assets/images/process/one-surveys.svg'
import one_behaviour from '../assets/images/process/one-behaviour.svg'
import one_contextual from '../assets/images/process/one-contextual.svg'
import one_hypothesis from '../assets/images/process/one-hypothesis.svg'

import two_stakeholders from '../assets/images/process/two-stakeholders.svg'
import two_problem from '../assets/images/process/two-problem.svg'
import two_personas from '../assets/images/process/two-personas.svg'
import two_pain from '../assets/images/process/two-pain.svg'
import two_interactions from '../assets/images/process/two-interactions.svg'
import two_insights from '../assets/images/process/two-insights.svg'
import two_empathy from '../assets/images/process/two-empathy.svg'
import two_customer from '../assets/images/process/two-customer.svg'

import three_uxstrategy from '../assets/images/process/three-uxstrategy.svg'
import three_uxdesign from '../assets/images/process/three-uxdesign.svg'
import three_triangle from '../assets/images/process/three-triangle.svg'
import three_prioritisation from '../assets/images/process/three-prioritisation.svg'
import three_new_user_journey from '../assets/images/process/three-new-user-jouney.svg'
import three_touchpoint from '../assets/images/process/three-ideate-touchpoint.svg'
import three_codesign from '../assets/images/process/three-codesign.svg'
import three_business from '../assets/images/process/three-business.svg'

import four_user_ab from '../assets/images/process/four-user-ab.svg'
import four_storyboard from '../assets/images/process/four-storyboard.svg'
import four_touchpoint from '../assets/images/process/four-proto-touchpoint.svg'
import four_learn from '../assets/images/process/four-learn.svg'
import four_kpi from '../assets/images/process/four-kpi.svg'
import four_gather from '../assets/images/process/four-gather.svg'

import five_blueprinting from '../assets/images/process/five-blueprinting.svg'
import five_further from '../assets/images/process/five-further.svg'
import five_future from '../assets/images/process/five-future.svg'
import five_implementation from '../assets/images/process/five-implementation.svg'
import five_poc from '../assets/images/process/five-poc.svg'
import five_roadmap from '../assets/images/process/five-roadmap.svg'
import { Link } from 'gatsby'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        dots: true,
      },
    },
  ],
}

// function toggleBody(this) {
//   console.log(this)
// }

class Accordion extends React.Component {
  constructor() {
    super()
    this._handleClick = this._handleClick.bind(this)
  }

  componentDidMount() {
    this._handleClick()
  }

  _handleClick() {
    const acc = this._acc.children
    for (let i = 0; i < acc.length; i++) {
      let a = acc[i]
      a.onclick = () => a.classList.toggle('active')
    }
  }

  render() {
    return (
      <div ref={a => (this._acc = a)} onClick={this._handleClick}>
        {this.props.children}
      </div>
    )
  }
}

const HowWork = props => (
  <Layout>
    <Helmet
      title="How I work | Portfolio Sagar Satam"
      meta={[
        { name: 'Design', content: 'Portfolio' },
        { name: 'Design', content: 'product design, designer' },
      ]}
    />

    <div id="main" className="alt">
      <section id="title-section" className="title-block inner">
        <div className="hp-featured">
          <h1 className="hp-featured__title">
            how <br />i work.
          </h1>
          <div className="hp-featured__content">
            <div className="hp-featured__hr-line" />
            <p className="hp-featured__text">
              The design process I have moulded is the result of having various
              experiences working on projects as a student & as a professional
              designer. My approach is flexible so that I can adjust accordingly
              to the different constraints, needs and expectations of the
              projects that i encounter. I use various tools & methodologies
              that enable me to bring together people, processes and technology
              to deliver unparalleled user experiences.
            </p>
          </div>
        </div>
      </section>

      <div className="how-i-work-wrapper" id="accordion-wrapper">
        <div className="how-i-work-steps">
          <Accordion>
            <section className="process-step first">
              <div className="step-name accordion-head first-accordion">
                <h2>RESEARCH & EMPATHISE</h2>
                <div className="arrow down" />
              </div>
              <article className="process-description accordion-body first-accordion-step">
                <ul>
                  <li className="process-method">
                    <img src={one_workshops} alt="" />
                    <p>
                      Brief definition
                      <br /> workshops
                    </p>
                  </li>
                  <li className="process-method">
                    <img src={one_market} alt="" />
                    <p>
                      Market
                      <br /> Evaluation
                    </p>
                  </li>
                  <li className="process-method">
                    <img src={one_trends} alt="" />
                    <p>
                      Trends &<br /> Inspirations
                    </p>
                  </li>
                  <li className="process-method">
                    <img src={one_benchmarking} alt="" />
                    <p>
                      Benchmarking <br />& Understanding
                    </p>
                  </li>
                </ul>
                <div className="step">
                  <span className="first-ball">RESEARCH & EMPATHISE</span>
                </div>
                <ul className="second-wrapper-icons">
                  <li className="process-method">
                    <img src={one_surveys} alt="" />
                    <p>
                      Surveys &<br /> Interviews
                    </p>
                  </li>
                  <li className="process-method">
                    <img src={one_behaviour} alt="" />
                    <p>
                      User behaviour
                      <br /> observations
                    </p>
                  </li>
                  <li className="process-method">
                    <img src={one_contextual} alt="" />
                    <p>
                      Contextual
                      <br /> understanding
                    </p>
                  </li>
                  <li className="process-method">
                    <img src={one_hypothesis} alt="" />
                    <p>
                      Hypothesis &<br /> Assumptions
                    </p>
                  </li>
                </ul>
                <div className="clear" />
              </article>
            </section>
          </Accordion>
          <Accordion>
            <section className="process-step second">
              <div className="step-name accordion-head second-accordion">
                <h2>ANALYSE & DEFINE</h2>
              </div>
              <article className="process-description accordion-body second-accordion-step">
                <ul>
                  <li className="process-method">
                    <img src={two_empathy} alt="" />
                    <p>
                      Empathy
                      <br />
                      map
                    </p>
                  </li>
                  <li className="process-method">
                    <img src={two_pain} alt="" />
                    <p>
                      Pain points &<br />
                      opportunities
                    </p>
                  </li>
                  <li className="process-method">
                    <img src={two_stakeholders} alt="" />
                    <p>
                      Stakeholder &<br />
                      System map
                    </p>
                  </li>
                  <li className="process-method">
                    <img src={two_problem} alt="" />
                    <p>
                      Problem <br />
                      definition workshop
                    </p>
                  </li>
                </ul>
                <div className="step">
                  <span className="second-ball">ANALYSE & DEFINE</span>
                </div>
                <ul className="second-wrapper-icons">
                  <li className="process-method">
                    <img src={two_insights} alt="" />
                    <p>
                      Insights &<br /> Jobs to be done
                    </p>
                  </li>
                  <li className="process-method">
                    <img src={two_personas} alt="" />
                    <p>
                      Personas & <br />
                      Scenarios
                    </p>
                  </li>
                  <li className="process-method">
                    <img src={two_customer} alt="" />
                    <p>
                      Customer <br />
                      journey map
                    </p>
                  </li>
                  <li className="process-method">
                    <img src={two_interactions} alt="" />
                    <p>
                      Interactions
                      <br />& Touchpoints
                    </p>
                  </li>
                </ul>
                <div className="clear" />
              </article>
            </section>
          </Accordion>
          <Accordion>
            <section className="process-step third">
              <div className="step-name accordion-head third-accordion">
                <h2>IDEATE & CONCEPT</h2>
              </div>
              <article className="process-description accordion-body third-accordion-step">
                <ul>
                  <li className="process-method">
                    <img src={three_new_user_journey} alt="" />
                    <p>
                      New user <br />
                      journey map
                    </p>
                  </li>
                  <li className="process-method">
                    <img src={three_prioritisation} alt="" />
                    <p>
                      Prioritisation <br />
                      framework
                    </p>
                  </li>
                  <li className="process-method">
                    <img src={three_uxdesign} alt="" />
                    <p>
                      Physical/Digital <br />
                      User exp. design
                    </p>
                  </li>
                  <li className="process-method">
                    <img src={three_codesign} alt="" />
                    <p>
                      Co-design <br />& conceptualise
                    </p>
                  </li>
                </ul>
                <div className="step">
                  <span className="third-ball">IDEATE & CONCEPT</span>
                </div>
                <ul className="second-wrapper-icons">
                  <li className="process-method">
                    <img src={three_triangle} alt="" />
                    <p>
                      Triangle & Cross <br />
                      mapping ideas
                    </p>
                  </li>
                  <li className="process-method">
                    <img src={three_touchpoint} alt="" />
                    <p>
                      Touchpoint
                      <br />
                      design
                    </p>
                  </li>
                  <li className="process-method">
                    <img src={three_business} alt="" />
                    <p>
                      Business
                      <br />
                      model
                    </p>
                  </li>
                  <li className="process-method">
                    <img src={three_uxstrategy} alt="" />
                    <p>UX strategy</p>
                  </li>
                </ul>
                <div className="clear" />
              </article>
            </section>
          </Accordion>
          <Accordion>
            <section className="process-step forth">
              <div className="step-name accordion-head forth-accordion">
                <h2>PROTOTYPE & TEST</h2>
              </div>
              <article className="process-description accordion-body forth-accordion-step">
                <ul>
                  <li className="process-method">
                    <img src={four_user_ab} alt="" />
                    <p>
                      User testing
                      <br />& validation
                    </p>
                  </li>
                  <li className="process-method">
                    <img src={four_kpi} alt="" />
                    <p>
                      Define KPI &<br />
                      other metrics
                    </p>
                  </li>
                  <li className="process-method">
                    <img src={four_touchpoint} alt="" />
                    <p>Touchpoint design</p>
                  </li>
                </ul>
                <div className="step">
                  <span className="forth-ball">
                    PROTOTYPE
                    <br />& TEST
                  </span>
                </div>
                <ul className="second-wrapper-icons">
                  <li className="process-method">
                    <img src={four_learn} alt="" />
                    <p>
                      Learn &<br />
                      consolidate
                    </p>
                  </li>
                  <li className="process-method">
                    <img src={four_storyboard} alt="" />
                    <p>
                      Storyboard &<br />
                      Service storming
                    </p>
                  </li>
                  <li className="process-method">
                    <img src={four_gather} alt="" />
                    <p>
                      Gather <br />
                      feedback
                    </p>
                  </li>
                </ul>
                <div className="clear" />
              </article>
            </section>
          </Accordion>
          <Accordion>
            <section className="process-step fifth">
              <div className="step-name accordion-head fifth-accordion">
                <h2>PLAN & DELIVER</h2>
              </div>
              <article className="process-description accordion-body fifth-accordion-step">
                <ul>
                  <li className="process-method">
                    <img src={five_blueprinting} alt="" />
                    <p>
                      Service
                      <br />
                      blueprinting
                    </p>
                  </li>
                  <li className="process-method">
                    <img src={five_implementation} alt="" />
                    <p>
                      Implementation
                      <br />
                      plan
                    </p>
                  </li>
                  <li className="process-method">
                    <img src={five_future} alt="" />
                    <p>
                      Future
                      <br />
                      envisioning
                    </p>
                  </li>
                </ul>
                <div className="step">
                  <span className="fifth-ball">
                    PLAN &<br /> DELIVER
                  </span>
                </div>
                <ul className="second-wrapper-icons">
                  <li className="process-method">
                    <img src={five_poc} alt="" />
                    <p>
                      Pilot/POC
                      <br />
                      planning
                    </p>
                  </li>
                  <li className="process-method">
                    <img src={five_roadmap} alt="" />
                    <p>
                      Roadmap
                      <br />& Goal setting
                    </p>
                  </li>
                  <li className="process-method">
                    <img src={five_further} alt="" />
                    <p>
                      Further
                      <br />
                      refining
                    </p>
                  </li>
                </ul>
                <div className="clear" />
              </article>
            </section>
          </Accordion>
        </div>
      </div>

      <section id="laws" className="laws inner">
        <div className="laws-wrapper">
          <h2 className="laws__title">
            laws, models & frameworks that i implement as part of my design
            strategy.
          </h2>
          <div className="laws__content">
            <div className="laws__wrapper">
              <Slider className="image-scroll" {...settings}>
                <img src={laws01} alt="" />
                <img src={laws02} alt="" />
                <img src={laws03} alt="" />
                <img src={laws04} alt="" />
                <img src={laws06} alt="" />
                <img src={laws07} alt="" />
                <img src={laws08} alt="" />
                <img src={laws09} alt="" />
                <img src={laws10} alt="" />
                <img src={laws12} alt="" />
                <img src={laws14} alt="" />
                <img src={laws15} alt="" />
              </Slider>
            </div>
          </div>
        </div>
      </section>

      <section id="footertopcta" className="inner">
        <p className="text arrow-front">
          now that you seen how i work,
          <br />
          checkout my <Link to="/#one">featured work</Link>
        </p>
      </section>
    </div>
  </Layout>
)

export default HowWork
